import html2canvas from 'html2canvas'

/**
 * 格式化日期
 * @param str
 * @param  {[type]} fmt [description]
 * @return {[type]}     [description]
 */
export function dateFormat(str, fmt = 'yyyy-MM-dd HH:mm:ss') {
  if (!str && isNaN(Date.parse(str))) return ''
  const date = new Date(str)
  const o = {
    'M+': date.getMonth() + 1, // 月份
    'd+': date.getDate(), // 日
    'h+': date.getHours() % 12 === 0 ? 12 : date.getHours() % 12, // 小时
    'H+': date.getHours(), // 小时
    'm+': date.getMinutes(), // 分
    's+': date.getSeconds(), // 秒
    'q+': Math.floor((date.getMonth() + 3) / 3), // 季度
    S: date.getMilliseconds() // 毫秒
  }
  if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
  for (const k in o) {
    if (new RegExp('(' + k + ')').test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)))
  }
  return fmt
}

/**
 * @desc 函数防抖
 * @param func 目标函数
 * @param wait 延迟执行毫秒数
 * @param immediate true - 立即执行， false - 延迟执行
 */
export function debounce(func, wait, immediate) {
  let timer = null
  return function () {
    const context = this
    const args = arguments
    if (timer) clearTimeout(timer)
    if (immediate) {
      const callNow = !timer
      timer = setTimeout(() => {
        timer = null
      }, wait)
      if (callNow) func.apply(context, args)
    } else {
      timer = setTimeout(() => {
        func.apply(context, args)
        timer = null
      }, wait)
    }
  }
}

/**
 * 函数节流
 * @param {function} fn 执行方法
 * @param {number} gapTime 间隔时间
 * @returns
 */
export function throttle(fn, gapTime) {
  let _lastTime = null
  return function () {
    const _nowTime = +new Date()
    if (_nowTime - _lastTime > gapTime || !_lastTime) {
      fn()
      _lastTime = _nowTime
    }
  }
}

// 是否为PC端
export function isPc() {
  const userAgentInfo = navigator.userAgent
  const Agents = [
    'Android',
    'iPhone',
    'SymbianOS',
    'Windows Phone',
    'iPad',
    'iPod'
  ]
  let flag = true
  for (let v = 0; v < Agents.length; v++) {
    if (userAgentInfo.indexOf(Agents[v]) > 0) {
      flag = false
      break
    }
  }
  return flag
}

/**
 *
 * @param contentId 需要生成图片的内容区id
 * @param targetContentId 生成完过后图片放哪个内容区的id  默认是contentId下面
 * @param isNeedAddPage  是否需要自动添加到页面上 默认是true
 * @param base64Jpeg  生成图片的base64
 * @param type  生成图片类型  默认'image/jpeg'
 * @param 默认返回 promise 对象
 */
export const saveImgToBase64OrCreated = async (
  {
    contentId = '',
    targetContentId = '',
    isNeedAddPage = true,
    type = 'image/jpeg',
    html2CanvasOptions = {}
  }) => {
  // 获取内容id
  let targetContent = document.getElementById(targetContentId)
  const content = document.getElementById(contentId)
  // 如果没有目标位置  则将生成位置就是目标位置
  if (!targetContentId) {
    targetContent = content
  }
  html2CanvasOptions = {
    userCORS: true,
    allowTaint: true, // 允许加载跨域的图片
    tainttest: true, // 检测每张图片都已经加载完成
    scale: 2, // 添加的scale 参数
    logging: false, // 日志开关，发布的时候记得改成false
    scrollY: 0,
    scrollX: isPc() ? -10 : 0,
    ...html2CanvasOptions
  }
  return new Promise((resolve, reject) => {
    window.pageYoffset = 0
    document.documentElement.scrollTop = 0
    document.body.scrollTop = 0
    html2canvas(content, html2CanvasOptions).then((canvas) => {
      // 读取属性值
      const base64Jpeg = canvas.toDataURL(type, 1.0)
      // 针对需要自动添加的处理
      if (isNeedAddPage) {
        targetContent.innerHTML = `<img width="100%" style="display: block;" src=${base64Jpeg}  alt="正在路上..."/>`
      }
      resolve({base64Jpeg})
    }).catch(e => reject(e))
  })
}

// 获取url相关参数
export function getQueryVariable(variable) {
  const query = window.location.search.substring(1)
  const vars = query.split('&')
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=')
    if (pair[0] === variable) {
      return pair[1]
    }
  }
  return false
}

// rem适配
export function toRem(pixel) {
  if (!pixel) {
    pixel = 750
  }
  (function (doc, win) {
    const docEl = doc.documentElement
    const resizeEvt =
      'orientationchange' in window ? 'orientationchange' : 'resize'
    const recalc = function () {
      const clientWidth = docEl.clientWidth
      if (!clientWidth) return
      if (clientWidth >= pixel) {
        docEl.style.fontSize = '75px'
      } else {
        docEl.style.fontSize = 100 * (clientWidth / pixel) + 'px'
      }
    }

    if (!doc.addEventListener) return
    win.addEventListener(resizeEvt, recalc, false)
    doc.addEventListener('DOMContentLoaded', recalc, false)
  })(document, window)
}

// 判断设备
export function getPlantForm() {
  let plantForm = ''
  const u = navigator.userAgent
  const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
  const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1 // android终端
  if (isiOS) {
    plantForm = 'ios'
  } else if (isAndroid) {
    plantForm = 'android'
  }

  return plantForm
}

// 统计页面访问量
export function visitFun() {
  // eslint-disable-next-line no-use-before-define
  var _hmt = _hmt || []
  window._hmt = _hmt; // 修改为window 全局变量
  return (function () {
    const hm = document.createElement('script')
    hm.src = 'https://hm.baidu.com/hm.js?a65c0ab491f55a0bdb4754d6358a8ae9'
    const s = document.getElementsByTagName('script')[0]
    s.parentNode.insertBefore(hm, s)
  })()
}

// 判断两数组是否相等
export function isEqual(a, b) {
  return [...new Set(a.concat(b))] // Get an array of unique elements of both arrays
    .filter(t =>
      // Filter on those elements whose count in both arrays is different
      a.filter(at => at === t).length !== b.filter(bt => bt === t).length
    )
    .length === 0
}

// 判断数组中是否包含另一个数组的值
export function getArrInclude(a, b) {
  let temp = []
  for (const item of b) {
    a.includes(item) ? temp.push(item) : ''
  }
  return !!temp.length
}

// 添加不重复的数组值
export function pushUnique(arr, value) {
  if (!Array.isArray(arr)) {
    throw new TypeError('The first argument must be an array.')
  }
  if (arr.includes(value)) {
    return // 如果值已经在数组中，不做操作直接返回
  }
  arr.push(value) // 如果值不在数组中，则推入
}

/**
 * 时间日期格式化
 * @param str
 * @param fmt
 * @returns {*}
 * @constructor
 */
export const DateFormat = (str, fmt) => {
  let date = new Date(str)
  let o = {
    'M+': date.getMonth() + 1,
    'd+': date.getDate(),
    'h+': date.getHours(),
    'm+': date.getMinutes(),
    's+': date.getSeconds(),
    'q+': Math.floor((date.getMonth() + 3) / 3),
    S: date.getMilliseconds()
  }
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(
      RegExp.$1,
      (date.getFullYear() + '').substring(4 - RegExp.$1.length)
    )
  }
  for (let k in o) {
    if (new RegExp('(' + k + ')').test(fmt)) {
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length === 1 ? o[k] : ('00' + o[k]).substring(('' + o[k]).length)
      )
    }
  }
  return fmt
}
