import vue from 'vue'
import svgIcon from '@/icons/svgIcon'

vue.component('svg-icon', svgIcon)

/*
* 第一个参数：'./svg' => 需要检索的目录
* 第二个参数：false => 是否检索子目录
* 第三个参数: /\.svg$/ => 匹配格式
*  */
const req = require.context('./svg', false, /\.svg$/)
// 导出svg文件
const requireAll = requireContext => {
  return requireContext.keys().map(requireContext)
}
requireAll(req)
