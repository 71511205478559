import Vue from 'vue'
import {
  Button,
  Toast,
  Search,
  Tab,
  Tabs,
  List,
  Tag,
  Lazyload,
  Swipe,
  SwipeItem,
  Loading,
  Popup,
  Overlay,
  Icon
} from 'vant'

const componentEle = {
  Button,
  Toast,
  Search,
  Tab,
  Tabs,
  List,
  Tag,
  Lazyload,
  Swipe,
  SwipeItem,
  Loading,
  Popup,
  Overlay,
  Icon
}

for (const componentEleKey in componentEle) {
  Vue.use(componentEle[componentEleKey])
}

Vue.prototype.$toast = Toast
