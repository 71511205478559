import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

// 防止不跳转页面，只修改路由参数报错
const originalReplace = VueRouter.prototype.replace

VueRouter.prototype.replace = function (location) {
  return originalReplace.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    name: 'Home',
    meta: {
      title: '首页',
      deepth: 0.5,
      keepAlive: true //需要被缓存
    },
    component: () => import('@/views/Home.vue')
  },
  {
    path: '/List',
    name: 'List',
    meta: {
      title: '课题列表',
      deepth: 1,
      keepAlive: true, //需要被缓存
      scrollTop: 0
    },
    component: () => import( '@/views/List/index.vue')
  },
  {
    path: '/BillClassify',
    name: 'BillClassify',
    meta: {
      title: '名额海报',
      deepth: 2
    },
    component: () => import('@/views/BillClassify.vue')
  },
  {
    path: '/BillClassify2',
    name: 'BillClassify2',
    meta: {
      title: '名额海报',
      deepth: 2
    },
    component: () => import('@/views/BillClassify2.vue')
  },
  {
    path: '/BillOverseas',
    name: 'BillOverseas',
    meta: {
      title: '名额海报',
      deepth: 2
    },
    component: () => import('@/views/BillOverseas.vue')
  },
  {
    path: '/BillClassify3',
    name: 'BillClassify3',
    meta: {
      title: '名额海报',
      deepth: 2
    },
    component: () => import('@/views/BillClassify3.vue')
  },
  {
    path: '/Professor',
    name: 'Professor',
    meta: {
      title: '课题海报',
      deepth: 2
    },
    component: () => import('@/views/Professor.vue')
  },
  {
    path: '/Detail',
    name: 'Detail',
    meta: {
      title: '',
      deepth: 1.5,
      keepAlive: true, //需要被缓存
    },
    component: () => import('@/views/Detail.vue')
  },
  {
    path: '/Disclaimers',
    name: 'Disclaimers',
    meta: {
      title: 'Disclaimers'
    },
    component: () => import('@/views/Disclaimers.vue')
  },
  {
    path: '/CalendarDetail',
    name: 'CalendarDetail',
    meta: {
      title: '',
      deepth: 2,
    },
    component: () => import('@/views/CalendarDetail.vue')
  },
  {
    path: '/school',
    name: 'School',
    meta: {
      title: '校区展示'
    },
    component: () => import('@/views/School.vue')
  },
  {
    path: '/schooldetail',
    name: 'SchoolDetail',
    meta: {
      title: ''
    },
    component: () => import('@/views/SchoolDetail.vue')
  },
  {
    path: "/school2",
    name: 'School2',
    component: () => import('@/views/School2.vue')
  },
  {
    path: "/school3",
    name: 'School3',
    component: () => import('@/views/School3.vue')
  },

  {
    path: '/posters',
    name: 'Posters',
    meta: {
      title: ''
    },
    component: () => import('@/views/Posters.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'notFound',
    component: () => import('@/views/404.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
