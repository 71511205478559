// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./PINGFANG BOLD.TTF", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./PINGFANG MEDIUM.TTF", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./NirmalaB.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("./Nirmala.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("./GILROY-BOLD.TTF", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\n    font-family: \"PingFang SC BoLd\";\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n@font-face {\n    font-family: \"PingFang SC Normal\";\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n}\n@font-face {\n    font-family: \"Nirmala UI Bold\";\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");\n}\n@font-face {\n    font-family: \"Nirmala UI Normal\";\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ");\n}\n@font-face {\n    font-family: \"Gilroy\";\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ");\n}\n", "",{"version":3,"sources":["webpack://./src/assets/font/font.css"],"names":[],"mappings":"AAAA;IACI,+BAA+B;IAC/B,4CAA+B;AACnC;AAEA;IACI,iCAAiC;IACjC,4CAAiC;AACrC;AAEA;IACI,8BAA8B;IAC9B,4CAA0B;AAC9B;AAEA;IACI,gCAAgC;IAChC,4CAAyB;AAC7B;AAEA;IACI,qBAAqB;IACrB,4CAA6B;AACjC","sourcesContent":["@font-face {\n    font-family: \"PingFang SC BoLd\";\n    src: url(\"./PINGFANG BOLD.TTF\");\n}\n\n@font-face {\n    font-family: \"PingFang SC Normal\";\n    src: url(\"./PINGFANG MEDIUM.TTF\");\n}\n\n@font-face {\n    font-family: \"Nirmala UI Bold\";\n    src: url(\"./NirmalaB.ttf\");\n}\n\n@font-face {\n    font-family: \"Nirmala UI Normal\";\n    src: url(\"./Nirmala.ttf\");\n}\n\n@font-face {\n    font-family: \"Gilroy\";\n    src: url(\"./GILROY-BOLD.TTF\");\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
