/** 调用浏览器storage
 * @param key
 * @param value
 * @param boolean
 */
export function setStorage(key, value, boolean) {
  let storageObj = null
  if (boolean) {
    storageObj = window.localStorage
  } else {
    storageObj = window.sessionStorage
  }
  if (Object.prototype.toString.call(value) === '[object Object]' || Object.prototype.toString.call(value) === '[object Array]') {
    value = JSON.stringify(value)
  }
  storageObj.setItem(key, value)
}
// 获取
export function getStorage(key, boolean) {
  let storageObj = null
  if (boolean) {
    storageObj = window.localStorage
  } else {
    storageObj = window.sessionStorage
  }
  return storageObj.getItem(key)
}
// 删除
export function removeStorage(key, boolean) {
  let storageObj = null
  if (boolean) {
    storageObj = window.localStorage
  } else {
    storageObj = window.sessionStorage
  }
  storageObj.removeItem(key)
}
// 清空所有
export function removeAllStorage (boolean) {
  let storageObj = null
  if (boolean) {
    storageObj = window.localStorage
  } else {
    storageObj = window.sessionStorage
  }
  storageObj.clear()
}
