import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/lib/utils/permission'
import 'vant/lib/index.css'
import './plugins/vant'
import './icons'
import $ from 'jquery'
// 引入模块后自动生效(pc端事件)
import '@vant/touch-emulator'

Vue.config.productionTip = false

Vue.prototype.$ = $ // jq
Vue.prototype.$preview = process.env.VUE_APP_PREVIEW // 资源中心地址
Vue.prototype.$pdfDown = process.env.VUE_APP_PDFDOWN // 详情页pdf预览下载
Vue.prototype.$wxCode = process.env.VUE_APP_WXCODE // 微信扫码地址
Vue.prototype.$pdfPreview = process.env.VUE_APP_PDFPREVIEW // PDF预览地址
Vue.prototype.$cissSite = process.env.VUE_APP_CISSSITE // ciss项目地址

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
