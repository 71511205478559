import Vue from 'vue'
import Vuex from 'vuex'
import {
  setStorage,
  getStorage,
  removeStorage,
  removeAllStorage
} from '@/lib/utils/auth'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    standardCode: getStorage('standardCode') || '', // 产品编码
    xids: getStorage('xids') || '', // 1级学科
    xid2s: getStorage('xid2s') || '', // 2级学科
    coursePhases: getStorage('coursePhases') || '', // 期数字典值
    coursePhases2: getStorage('coursePhases2') || '', // 开课月份（1月：1， 3月：2，6月：3，7月：4，10月：5）
    courseCity: getStorage('courseCity') || '', // 校区
    nationality: getStorage('nationality') || '', // 导师院校
    searchVal: getStorage('searchVal') || '', // 搜索
  },
  getters: {
    standardCode: state => state.standardCode,
    xids: state => state.xids,
    xid2s: state => state.xid2s,
    coursePhases: state => state.coursePhases,
    coursePhases2: state => state.coursePhases2,
    courseCity: state => state.courseCity,
    nationality: state => state.nationality,
    searchVal: state => state.searchVal,
  },
  mutations: {
    SET_STANDARDCODE(state, value) {
      state.standardCode = value;
      setStorage('standardCode', value)
    },
    SET_XIDS(state, value) {
      state.xids = value;
      setStorage('xids', value)
    },
    SET_XID2S(state, value) {
      state.xid2s = value;
      setStorage('xid2s', value)
    },
    SET_COURSEPHASES(state, value) {
      state.coursePhases = value;
      setStorage('coursePhases', value)
    },
    SET_COURSEPHASES2(state, value) {
      state.coursePhases2 = value;
      setStorage('coursePhases2', value)
    },
    SET_COURSECITY(state, value) {
      state.courseCity = value;
      setStorage('courseCity', value)
    },
    SET_NATIONALITY(state, value) {
      state.nationality = value;
      setStorage('nationality', value)
    },
    SET_SEARCHVAL(state, value) {
      state.searchVal = value;
      setStorage('searchVal', value)
    }
  },
  actions: {
    // 清空
    clearStorage(context, tag) {
      switch (tag) {
        case 'standardCode':
          context.commit('SET_STANDARDCODE', '')
          removeStorage('standardCode');
          break;
        case 'xids':
          context.commit('SET_XIDS', '')
          removeStorage('xids');
          break;
        case 'xid2s':
          context.commit('SET_XID2S', '')
          removeStorage('xid2s');
          break;
        case 'coursePhases':
          context.commit('SET_COURSEPHASES', '')
          removeStorage('coursePhases');
          break;
        case 'coursePhases2':
          context.commit('SET_COURSEPHASES2', '')
          removeStorage('coursePhases2');
          break;
        case 'courseCity':
          context.commit('SET_COURSECITY', '')
          removeStorage('courseCity');
          break;
        case 'nationality':
          context.commit('SET_NATIONALITY', '')
          removeStorage('nationality');
          break;
        case 'searchVal':
          context.commit('SET_SEARCHVAL', '')
          removeStorage('searchVal');
          break;
        default:
          context.commit('SET_STANDARDCODE', '')
          context.commit('SET_XIDS', '')
          context.commit('SET_XID2S', '')
          context.commit('SET_COURSEPHASES', '')
          context.commit('SET_COURSEPHASES2', '')
          context.commit('SET_COURSECITY', '')
          context.commit('SET_NATIONALITY', '')
          context.commit('SET_SEARCHVAL', '')
          removeAllStorage()
          break;
      }
    }
  },
  modules: {
  }
})
