import router from '@/router'
import {visitFun} from '@/lib/utils'

visitFun()

router.beforeEach((to, from, next) => {
  // 页签title设置
  to.meta.title && (document.title = to.meta.title)

  if (window._hmt) {
    if (to.path) {
      window._hmt.push(['_trackPageview', to.fullPath])
    }
  }

  next()
})
